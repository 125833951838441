.ellipsis-0 {
  opacity: 0;
  animation: 1.5s ease-in-out 0s infinite running fade; 
}

.ellipsis-1 {
  opacity: 0;
  animation: 1.5s ease-in-out 0.2s infinite running fade; 
}

.ellipsis-2 {
  opacity: 0;
  animation: 1.5s ease-in-out 0.4s infinite running fade; 
}

@keyframes fade {
  25% {
    opacity: 1;    
  }
  75% {
    opacity: 0;
  }
}
