.cascading-image-container {
  /* Prevent vertical gaps */
  line-height: 0;
   
  -webkit-column-count: 6;
  -webkit-column-gap:   0px;
  -moz-column-count:    6;
  -moz-column-gap:      0px;
  column-count:         6;
  column-gap:           0px;  
}

.cascading-image-container img {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto !important;
}

@media (max-width: 1200px) {
  .cascading-image-container {
  -moz-column-count:    4;
  -webkit-column-count: 4;
  column-count:         4;
  }
}
@media (max-width: 1000px) {
  .cascading-image-container {
  -moz-column-count:    3;
  -webkit-column-count: 3;
  column-count:         3;
  }
}
@media (max-width: 800px) {
  .cascading-image-container {
  -moz-column-count:    2;
  -webkit-column-count: 2;
  column-count:         2;
  }
}
@media (max-width: 400px) {
  .cascading-image-container {
  -moz-column-count:    1;
  -webkit-column-count: 1;
  column-count:         1;
  }
}