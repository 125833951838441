@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

.coactive-cm-container {
  position: relative;
  min-height: 6rem;
}

.coactive-cm {

  &, & .cm-editor {
    height: 100%;
  }

  &,
  .ͼ1 .cm-scroller,
  .ͼ1 .cm-tooltip.cm-tooltip-autocomplete > ul {
    font-family: 'Source Code Pro', monospace !important;
  }

  .ͼ1.cm-editor, .cm-scroller {
    border-radius: 0.5rem;
  }

  .ͼ1.cm-editor.cm-focused {
    outline: none;
    outline: 2px solid rgb(37, 99, 235);
  }

  .ͼa {
    color: #16a34a;
  }

  .cm-completionIcon {
    padding-right: 1rem;
  }
}
