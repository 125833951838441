@tailwind base;
@tailwind components;
@tailwind utilities;

code,
kbd,
samp,
pre {
  font-size: 0.75rem; /* text-xs */
  line-height: 1rem; /* leading-4 */
}

ol.list-lower-alpha-no-period {
  counter-reset: list;
  list-style: none;
  padding-left: 30px;

  li {
    counter-increment: list;
    position: relative;

    &::before {
      content: counter(list, lower-alpha);
      margin-right: 10px;
      margin-left: 10px;
      position: absolute;
      right: 100%;
      color: rgb(107 114 128);
      font-weight: 600;
    }
  }
}
